/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResult, ProductBasketRequest, ProductBasketResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ProductBasket<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ProductBasket
   * @name ProductbasketCreate
   * @summary Calculate basket based on incoming basket lines.
   * @request POST:/api/productbasket
   * @secure
   */
  productbasketCreate = (data: ProductBasketRequest, params: RequestParams = {}) =>
    this.request<ProductBasketResponse, ErrorResult | void>({
      path: `/api/productbasket`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductBasket
   * @name ProductbasketCommitprebasketCreate
   * @summary Saves the pre-basket.
   * @request POST:/api/productbasket/commitprebasket
   * @secure
   */
  productbasketCommitprebasketCreate = (data: ProductBasketRequest, params: RequestParams = {}) =>
    this.request<ProductBasketResponse, ErrorResult | void>({
      path: `/api/productbasket/commitprebasket`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
