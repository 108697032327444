import React, { Dispatch, SetStateAction } from 'react';
import { useTranslationQuery } from 'api/translations';
import { Badge } from 'components/shared/Badge';
import { Button, ButtonProps } from 'components/shared/Button';
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeaderWBackButton,
	DrawerLevel,
} from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { Sorting, SortingResponse } from 'components/shared/Sorting';
import { FilterRequest, FilterResponse, FilterType, PriceSliderResponse } from 'generated/data-contracts';
import { FilterContent } from '../FilterContent';
import { PriceQueryProps } from '../RangeFilter';
import { setActiveCount, setActivePriceCount } from '../helpers';
import styles from './MobileFilterMenu.module.scss';

interface MobileFilterMenuProps {
	filters: FilterResponse[];
	changeFilterMobile: (sortBy: string, sortDirection: string) => void;
	priceSliderValues?: PriceSliderResponse;
	buttonClassName?: string;
	isMobile?: boolean;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	priceFilter?: PriceQueryProps;
	setPriceFilter?: Dispatch<SetStateAction<PriceQueryProps>>;
	resetFilters: () => void;
	sorting?: SortingResponse[];
	currentSorting?: SortingResponse;
	buttonVariant?: ButtonProps['variant'];
	buttonSize?: ButtonProps['size'];
}

export const MobileFilterMenu: React.FunctionComponent<MobileFilterMenuProps> = ({
	filters,
	changeFilterMobile,
	priceSliderValues,
	buttonClassName,
	isMobile,
	toBeSet,
	toBeRemoved,
	setToBeSetFilters,
	setToBeRemovedFilters,
	priceFilter,
	setPriceFilter,
	resetFilters,
	sorting,
	currentSorting,
	buttonSize = 'sm',
	buttonVariant = 'dark',
}) => {
	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = React.useState(false);
	const [activeLevel, setActiveLevel] = React.useState<string | null>(null);
	const [renderedLevels, setRenderedLevels] = React.useState<(string | null)[]>([]);
	const [sortBy, setSortBy] = React.useState(currentSorting?.sortBy || '');
	const [sortByDirection, setSortByDirection] = React.useState(currentSorting?.sortDirection || '');

	const hasChanged =
		(priceFilter?.minPrice && priceFilter?.minPrice !== priceSliderValues?.selectedMinPrice) ||
		(priceFilter?.maxPrice && priceFilter?.maxPrice !== priceSliderValues?.selectedMaxPrice) ||
		(toBeSet?.length ?? 0) > 0 ||
		(toBeRemoved?.length ?? 0) > 0;

	const resetActiveLevel = (): void => setActiveLevel(null);

	const handleClose = (): void => {
		setIsOpen(false);

		setTimeout(() => {
			resetActiveLevel();
		}, 400);
	};

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const setSorting = (sort: string, sortDirection: string): void => {
		setSortBy(sort);
		setSortByDirection(sortDirection);
	};

	const handleLevelActivation = (levelId: string): void => {
		setActiveLevel(levelId);
		setRenderedLevels((arr) => [...arr, levelId]);
	};

	const handleReset = (): void => {
		resetFilters();
		handleClose();
	};

	const handleSubmit = (): void => {
		changeFilterMobile(sortBy, sortByDirection);
		handleClose();
	};

	const handleBack = (): void => {
		changeFilterMobile(sortBy, sortByDirection);
		resetActiveLevel();
	};

	const renderFooter = (): React.ReactNode => {
		if (
			!hasChanged &&
			!filters.some((f) => f?.values?.some((v) => v.isSelected) || f?.selectedMin || f?.selectedMax) &&
			(!priceSliderValues || (!priceSliderValues?.selectedMinPrice && !priceSliderValues?.selectedMaxPrice)) &&
			!(sortBy !== currentSorting?.sortBy || sortByDirection !== currentSorting?.sortDirection)
		)
			return null;

		return (
			<div className={styles.footer}>
				<Button className={styles.footerBtn} variant="outline" size="sm" onClick={handleReset}>
					{translations?.productList.filters.resetFilters}
				</Button>
				<Button className={styles.footerBtn} variant="dark" size="sm" onClick={handleSubmit}>
					{translations?.productList.filters.showProductsButton}
				</Button>
			</div>
		);
	};

	const getActiveCount = React.useCallback(
		(filter): number => {
			if (filter) {
				return setActiveCount(filter);
			}

			return setActivePriceCount(priceSliderValues);
		},
		[priceSliderValues],
	);

	const renderFilterItem = (item?: FilterResponse): React.ReactNode => {
		const priceId = 'price';
		const priceLabel = translations?.productList.filterDisplayName.price;

		const getId = (): string => (item ? item.id : priceId);

		return (
			<>
				<Button
					className={styles.filterButton}
					variant={'ghost'}
					hasOnlyIcon
					onClick={(): void => handleLevelActivation(getId())}
				>
					<span className={styles.filterLabelWrapper}>
						<span className={styles.filterLabel}>{item ? item.name : priceLabel}</span>
						<Badge innerClassName={styles.badge} value={getActiveCount(item)} size={'sm'} />
					</span>
					<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
				</Button>

				{activeLevel === getId() && (
					<DrawerLevel isActive={activeLevel === getId()}>
						{renderedLevels.includes(getId()) && (
							<DrawerHeaderWBackButton
								handleClose={handleSubmit}
								handleBack={handleBack}
								title={item ? item.name : priceLabel}
							/>
						)}
						{item ? (
							<FilterContent
								{...item}
								isFullScreen
								toBeSet={toBeSet}
								toBeRemoved={toBeRemoved}
								setToBeSetFilters={setToBeSetFilters}
								setToBeRemovedFilters={setToBeRemovedFilters}
								priceFilter={priceFilter}
								setPriceFilter={setPriceFilter}
							/>
						) : (
							<FilterContent
								type={FilterType.Number}
								name={translations?.productList.filters.price || ''}
								id={'price'}
								min={priceSliderValues?.minPrice}
								max={priceSliderValues?.maxPrice}
								selectedMin={priceSliderValues?.selectedMinPrice}
								selectedMax={priceSliderValues?.selectedMaxPrice}
								currencySymbol={priceSliderValues?.currencySymbol}
								isPriceFilter
								isFullScreen
								toBeSet={toBeSet}
								toBeRemoved={toBeRemoved}
								setToBeSetFilters={setToBeSetFilters}
								setToBeRemovedFilters={setToBeRemovedFilters}
								priceFilter={priceFilter}
								setPriceFilter={setPriceFilter}
							/>
						)}
						<DrawerFooter className={styles.footerContainer}>{renderFooter()}</DrawerFooter>
					</DrawerLevel>
				)}
			</>
		);
	};

	return (
		<>
			{/* Trigger button */}
			<Button className={buttonClassName} size={buttonSize} variant={buttonVariant} onClick={handleOpen}>
				<Icon name={'filters'} size={'md'} />
				<span>{translations?.productList.filters.filtersAndSort}</span>
			</Button>

			{/* Filter menu */}
			{isMobile && isOpen && (
				<Drawer onClose={handleClose} direction="Right">
					<DrawerHeader
						handleClose={handleClose}
						title={translations?.productList?.filters?.allFilters}
						titleSize="md"
						size="sm"
					/>
					<DrawerBody className={styles.mainContent}>
						<ul className={styles.list}>
							{sorting && currentSorting && (
								<li className={styles.item}>
									<Sorting
										sorting={sorting}
										isMobile={isMobile}
										currentSorting={currentSorting}
										setSorting={setSorting}
										activeLevel={activeLevel || ''}
										handleClose={handleClose}
										handleBack={resetActiveLevel}
										handleLevelActivation={handleLevelActivation}
									/>
								</li>
							)}
							{/* Regular filters */}
							{filters.map((item, index) => (
								<li className={styles.item} key={`${item?.id}-${index}`}>
									{renderFilterItem(item)}
								</li>
							))}
						</ul>
					</DrawerBody>
					<DrawerFooter className={styles.footerContainer}>{renderFooter()}</DrawerFooter>
				</Drawer>
			)}
		</>
	);
};
