import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon, Link, Logo } from 'components/shared';
import { Area, BrandResponse, PageType, SubBrandResponse } from 'generated/data-contracts';
import BrandGroup, { AnimationProps } from '../BrandGroup';
import globalStyles from '../BrandSelector.module.scss';
import BrandSelectSectionDesktop from './BrandSelectSectionDesktop';
import styles from './BrandSelectorDesktop.module.scss';

export interface BrandSelectorDesktopProps {
	brands: BrandResponse[];
	isDrawer?: boolean;
	accountSelectorUrl?: string;
	onChooseBrand: (brand: BrandResponse | SubBrandResponse) => void;
}

const BrandSelectorDesktop: FC<BrandSelectorDesktopProps> = ({
	brands,
	isDrawer = false,
	accountSelectorUrl,
	onChooseBrand,
}) => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();

	const [brandGroups, setBrandGroups] = useState<Record<number, BrandResponse[]>>({});
	const [closingBrand, setClosingBrand] = useState<BrandResponse | undefined>();
	const [closingBrandGroupId, setClosingBrandGroupId] = useState<string | undefined>();
	const [isAnimating, setIsAnimating] = useState<boolean>(false);
	const [isCloseAnimation, setIsCloseAnimation] = useState<boolean>(false);
	const [selectedBrand, setSelectedBrand] = useState<BrandResponse | undefined>();
	const [selectedBrandGroupId, setSelectedBrandGroupId] = useState<string | undefined>();

	const baseContentHeight = '166rem';
	const brandGroupColumnSize = 3;
	const brandLogoHeight = '7.5rem';
	const logoSize = '9.375rem';

	const staticLinks = layout?.staticLinks;

	let accountUrl = accountSelectorUrl;

	if (!accountUrl && staticLinks) {
		accountUrl = staticLinks.find((item) => item.pageType === PageType.AccountSelector)?.url;
	}

	React.useEffect(() => {
		const newBrandGroups: Record<number, BrandResponse[]> = {};

		for (let i = 0; i < brands.length; i += brandGroupColumnSize) {
			const brandChunk = brands.slice(i, i + brandGroupColumnSize);

			newBrandGroups[i] = brandChunk;
		}

		setBrandGroups(newBrandGroups);
	}, [brands]);

	const getHeight = (contentHeight: SupportedCssUnits, logoHeight: SupportedCssUnits, columnSize: number): string => {
		if (selectedBrand) {
			const numberOfSubBrands = selectedBrand.subBrands.length;
			const heightFactor = Math.ceil(numberOfSubBrands / columnSize);
			const gapSize = '.25rem';

			return `calc(${contentHeight} + ${heightFactor} * (${logoHeight} + ${gapSize}))`;
		}

		return contentHeight;
	};

	const onAnimationEnd = (brandGroupId: string): void => {
		if (isCloseAnimation && selectedBrandGroupId !== brandGroupId) {
			setClosingBrandGroupId(undefined);
			setClosingBrand(undefined);
		}
		setIsAnimating(false);
	};

	const onAnimationStart = (): void => {
		setIsAnimating(true);
	};

	const onCloseBrand = (): void => {
		setIsCloseAnimation(true);

		setClosingBrandGroupId(selectedBrandGroupId);
		setClosingBrand(selectedBrand);

		setSelectedBrandGroupId(undefined);
		setSelectedBrand(undefined);
	};

	const onBrandClick = (brandGroupId: string, brand: BrandResponse): void => {
		if (brand.subBrands.length === 0) {
			onChooseBrand(brand);
			return;
		}

		if (!brand.active && brand.subBrands.length === 1) {
			onChooseBrand(brand.subBrands[0]);
			return;
		}

		if (brand === selectedBrand) {
			onCloseBrand();
		} else if (selectedBrandGroupId !== brandGroupId) {
			setIsCloseAnimation(true);

			setClosingBrandGroupId(selectedBrandGroupId);
			setClosingBrand(selectedBrand);

			setSelectedBrandGroupId(brandGroupId);
			setSelectedBrand(brand);
		} else {
			setIsCloseAnimation(false);
			setSelectedBrandGroupId(brandGroupId);
			setSelectedBrand(brand);
		}
	};

	const getAnimationProps = (): AnimationProps => ({
		closingBrand: closingBrand,
		closingBrandGroupId: closingBrandGroupId,
		isAnimating: isAnimating,
		selectedBrand: selectedBrand,
		selectedBrandGroupId: selectedBrandGroupId,
		getHeight: (): string => getHeight(baseContentHeight, brandLogoHeight, brandGroupColumnSize),
		onAnimationEnd: onAnimationEnd,
		onAnimationStart: onAnimationStart,
	});

	const getBrand = (brandGroupId: string): BrandResponse | undefined => {
		if (selectedBrandGroupId === brandGroupId) {
			return selectedBrand || closingBrand;
		} else {
			return closingBrand;
		}
	};

	const route = {
		area: Area.StaticPages,
		pageType: PageType.AccountSelector,
		editAccounts: true,
	};

	return (
		<>
			{!isDrawer && (
				<header className={classNames(globalStyles.header, styles.headerPadding)}>
					<div className={styles.logoWrapper}>
						<Link to={accountUrl} state={route}>
							<Icon name={'chevronDown'} rotate={90} size="lg" />
						</Link>
						<Logo name={'B_BS'} width={'9.375rem'} height={'2rem'} />
					</div>
				</header>
			)}

			<div className={classNames(globalStyles.bodyWrapper, { ['u-container-lg']: isDrawer })}>
				<ul className={classNames({ ['u-container-lg']: !isDrawer, [styles.drawerContainer]: isDrawer })}>
					<>
						{!isDrawer && (
							<li className={classNames(globalStyles.bodyHeader, styles.bodyHeaderPadding)}>
								{translations?.brandSelector.chooseBrand}
							</li>
						)}
						{brandGroups && (
							<li className={styles.brandWrapper}>
								{Object.entries(brandGroups).map(([brandGroupId, brandGroup]) => {
									const brand = getBrand(brandGroupId);
									return (
										<BrandGroup
											key={`${brandGroupId}`}
											animationsProps={getAnimationProps()}
											brandGroup={brandGroup}
											brandGroupId={brandGroupId}
											columnSize={brandGroupColumnSize}
											logoSize={logoSize}
											logoHeight={brandLogoHeight}
											onBrandClick={onBrandClick}
										>
											{brand && (
												<BrandSelectSectionDesktop
													brand={brand}
													brandLogoHeight={brandLogoHeight}
													onChooseBrand={onChooseBrand}
												/>
											)}
											<Button
												className={styles.closeButton}
												hasOnlyIcon
												size="xs"
												variant="outline"
												onClick={onCloseBrand}
											>
												<Icon name={'close'} size="sm" />
											</Button>
										</BrandGroup>
									);
								})}
							</li>
						)}
					</>
				</ul>
				<div className={classNames(globalStyles.manageAccountsWrapper, globalStyles.manageAccountsPosition)}>
					<span>{translations?.brandSelector.missingBrand}</span>
					<Link className={globalStyles.link} to={accountUrl} state={route}>
						{translations?.brandSelector.manageAccounts}
					</Link>
				</div>
			</div>
		</>
	);
};

export default BrandSelectorDesktop;
