import { useDispatch, useSelector } from 'react-redux';
import { useQuery, UseQueryResult, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { ShipTo as ShipToApi } from 'generated/ShipTo';
import { ShipToListResponse, ProblemDetails, ShipToId, FavouriteShipToFilterResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import { useTranslationQuery } from './translations';

const DefaultPageSize = 150;

const mapResult = (data: ShipToListResponse, sortResult: boolean): ShipToListResponse => {
	return {
		totalCount: data.totalCount,
		shipTos: sortResult ? sortBy(data.shipTos, (account) => account.positionNumber) : data.shipTos,
	};
};

export interface ShipToBasedOnBasketItemsQuery {
	phrase?: string;
	page?: number;
	pageSize?: number;
	selectedShipToIds: string[];
}

export interface UseShiptoSearchOptions {
	phrase?: string;
	page?: number;
	pageSize?: number;
	sortResult?: boolean;
	isEnabled?: boolean;
	selectedShipTos?: string[];
}
export const useShiptoSearchQuery = ({
	phrase,
	page = 1,
	sortResult = true,
	pageSize = DefaultPageSize,
	isEnabled = true,
	selectedShipTos = [],
}: UseShiptoSearchOptions): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.search(segmentationId, page, phrase, selectedShipTos).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchList({
				Phrase: phrase,
				page,
				pageSize: pageSize + selectedShipTos?.length,
				SelectedShipToIds: selectedShipTos,
			});

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
		keepPreviousData: true,
		select: (d) => mapResult(d, sortResult),
		enabled: isEnabled,
	});
};

export const useShiptoSelectedListQuery = (): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.shipTo.listselected(segmentationId).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoListselectedList();

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorFetchingSelectedAccounts, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
	});
};

export const useSearchBasedOnBasketItemsQuery = (
	query: ShipToBasedOnBasketItemsQuery = {} as ShipToBasedOnBasketItemsQuery,
): UseQueryResult<ShipToListResponse> => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.shipTo.searchbasedonbasketitems('basket', query).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchbasedonbasketitemsList(query);

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: 'always',
	});
};

export const useShiptoSearchFavouriteQuery = ({
	phrase,
	selectedCustomerGroupIds,
	selectedSalesRepIds,
	page = 1,
	sortResult = true,
	isEnabled = true,
	selectedShipTos = [],
}: UseShiptoSearchOptions & {
	selectedCustomerGroupIds: string[];
	selectedSalesRepIds: string[];
}): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.shipTo.searchFavourite(
			segmentationId,
			page,
			phrase,
			selectedCustomerGroupIds,
			selectedSalesRepIds,
		).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSearchfavouriteCreate({
				phrase,
				page,
				pageSize: DefaultPageSize + selectedShipTos?.length,
				...(selectedCustomerGroupIds.length > 0 ? { SelectedCustomerGroupIds: selectedCustomerGroupIds } : {}),
				...(selectedSalesRepIds.length > 0 ? { SelectedSalesReps: selectedSalesRepIds } : {}),
				...(selectedShipTos.length > 0 ? { SelectedShipToIds: selectedShipTos } : {}),
			});

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
		keepPreviousData: true,
		select: (d) => mapResult(d, sortResult),
		enabled: isEnabled,
	});
};

export const useShiptoSelectedFavouriteListQuery = (): UseQueryResult<ShipToListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.shipTo.favouriteListSelected(segmentationId).queryKey,
		queryFn: async (): Promise<ShipToListResponse> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoListselectedfavouriteList();

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorFetchingSelectedAccounts, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
	});
};

export const useShiptoCustomerGroupFilterQuery = ({
	phrase,
	page = 1,
	isEnabled = true,
}: UseShiptoSearchOptions): UseQueryResult<FavouriteShipToFilterResponse | ProblemDetails> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.shipTo.customerGroupFilter(segmentationId, page, phrase).queryKey,
		queryFn: async (): Promise<void | ProblemDetails> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoCustomergroupfilterList({
				searchPhrase: phrase,
				page,
				pageSize: DefaultPageSize,
			});

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
		keepPreviousData: true,
		enabled: isEnabled,
	});
};

export const useShiptoSalesRepFilterQuery = ({
	phrase,
	page = 1,
	isEnabled = true,
}: UseShiptoSearchOptions): UseQueryResult<FavouriteShipToFilterResponse | ProblemDetails> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();

	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.shipTo.salesRepFilter(segmentationId, page, phrase).queryKey,
		queryFn: async (): Promise<void | ProblemDetails> => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			const result = await shipToApi.shiptoSalesrepfilterList({
				searchPhrase: phrase,
				page,
				pageSize: DefaultPageSize,
			});

			if (!result.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.accountSelector.errorSearchingForUsersShipTos, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return result.data;
		},
		keepPreviousData: true,
		enabled: isEnabled,
	});
};

interface UpdateFavouritesRequest {
	shipToIds: ShipToId[];
}

export const useShiptoUpdateFavouritesMutation = (): UseMutationResult<
	HttpResponse<void, void | ProblemDetails>,
	HttpResponse<void>,
	UpdateFavouritesRequest
> => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const shipToApi = new ShipToApi({ baseApiParams: { headers: setHeaders() } });

			return shipToApi.shiptoUpdatefavouriteshiptosCreate({
				shipToIds: variables.shipToIds,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.shipTo._def });
		},
	});
};
