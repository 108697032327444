import React, { PropsWithChildren, RefObject, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useCarouselStore } from '../state/CarouselContext';
import styles from './CarouselItem.module.scss';

export const CarouselItem: React.FunctionComponent<
	PropsWithChildren<{
		className?: string;
		index: number;
		listRef: RefObject<HTMLUListElement>;
	}>
> = ({ index, listRef, className, children }) => {
	const {
		slides: { setActiveSlide, activeSlide, intersectionEnabled },
	} = useCarouselStore();

	const ref = useRef<HTMLLIElement | null>(null);

	useEffect(() => {
		const ACTIVE_SLIDE_THRESHOLD = 10;
		const PADDING = 2;
		const scrollLeft = listRef.current?.scrollLeft ?? 0;
		const clientWidth = (listRef.current?.clientWidth ?? PADDING * 2) - PADDING * 2;
		const expectedWidth = index * clientWidth;
		const isActiveSlide =
			clientWidth > 0 &&
			scrollLeft >= expectedWidth - ACTIVE_SLIDE_THRESHOLD &&
			scrollLeft <= expectedWidth + ACTIVE_SLIDE_THRESHOLD;
		if (isActiveSlide && activeSlide !== index && intersectionEnabled) {
			setActiveSlide(index, false);
		}
	}, [
		setActiveSlide,
		index,
		activeSlide,
		intersectionEnabled,
		listRef,
		listRef.current?.clientWidth,
		listRef.current?.scrollLeft,
	]);

	const isActiveSlide = activeSlide === index;

	useEffect(() => {
		if (!ref.current) return;
		if (isActiveSlide) {
			ref.current.removeAttribute('inert');
			return;
		}
		ref.current.setAttribute('inert', 'true');
	}, [isActiveSlide]);

	return (
		<li
			className={classNames(className, styles.slide)}
			ref={ref}
			data-slide-number={index}
			aria-hidden={!isActiveSlide}
		>
			{children}
		</li>
	);
};
