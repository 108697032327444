import React from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { useTranslationQuery } from 'api/translations';
import { Button, DrawerBody, Logo } from 'components/shared';
import { BrandResponse, SubBrandResponse } from 'generated/data-contracts';
import globalStyles from '../../BrandSelector.module.scss';
import styles from './BrandSelectSectionMobile.module.scss';

export interface BrandSelectSectionMobileProps {
	brand: BrandResponse;
	brandLogoHeight: SupportedCssUnits;
	lightColorMode?: boolean;
	isDrawer?: boolean;
	isInsideDrawer?: boolean;
	onChooseBrand: (brand: BrandResponse | SubBrandResponse) => void;
}

const BrandSelectSectionMobile: React.FunctionComponent<BrandSelectSectionMobileProps> = ({
	brand,
	brandLogoHeight,
	lightColorMode,
	isDrawer,
	isInsideDrawer,
	onChooseBrand,
}) => {
	const { data: translations } = useTranslationQuery();

	const subBrands = brand?.subBrands || [];
	const showSubBrands = subBrands.length > 0;
	const showMainBrand = brand.active;

	const createBrandButton = (brandResponse: BrandResponse | SubBrandResponse): React.ReactElement => {
		return (
			<Button
				hasNoStyles
				className={classNames(globalStyles.brandButton, { [styles.primaryColorBackground]: lightColorMode })}
				onClick={(): void => onChooseBrand(brandResponse)}
			>
				<Logo name={brandResponse.brandId} height={brandLogoHeight} width={'9.1875rem'} />
			</Button>
		);
	};

	const getColorBackground = (): string => {
		return lightColorMode ? styles.lightColorBackground : styles.primaryColorBackground;
	};

	return (
		<DrawerBody className={classNames(getColorBackground(), { [styles.reducedPadding]: isInsideDrawer })}>
			<div
				className={classNames(
					{
						[styles.contentWrapper]: !isDrawer,
						[styles.drawerContentWrapper]: isDrawer,
						[styles.insideDrawerContentWrapper]: isInsideDrawer,
					},
					getColorBackground(),
				)}
			>
				{showMainBrand && (
					<div className={styles.mainBrandWrapper}>
						<div className={classNames(globalStyles.bodyHeader, styles.enterBrandPadding)}>
							{translations?.brandSelector.enterBrand}
						</div>
						<div key={`${brand.brandId}`} className={globalStyles.brandSectionWrapper}>
							{createBrandButton(brand)}
						</div>
					</div>
				)}
				{showSubBrands && (
					<div>
						<div className={classNames(globalStyles.bodyHeader, styles.enterBrandPadding)}>
							{translations?.brandSelector.enterSubBrand}
						</div>
						<div className={styles.subBrandGrid}>
							{subBrands.map((subBrand) => {
								return (
									<div key={`${subBrand.brandId}`} className={globalStyles.brandSectionWrapper}>
										{createBrandButton(subBrand)}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</DrawerBody>
	);
};

export default BrandSelectSectionMobile;
