/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorResult,
  FavouriteShipToFilterResponse,
  FavouriteShipToSearchRequest,
  ProblemDetails,
  ShipToId,
  ShipToListResponse,
  UpdateFavouriteShipToRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ShipTo<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoSearchList
   * @summary Retrieves the user's ShipTos, optionally filtered on name and id
   * @request GET:/api/shipto/search
   * @secure
   */
  shiptoSearchList = (
    query?: {
      Phrase?: string;
      SelectedShipToIds?: ShipToId[];
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ShipToListResponse, ProblemDetails | void>({
      path: `/api/shipto/search`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoListselectedList
   * @summary Retrieves the user's selected ShipTos
   * @request GET:/api/shipto/listselected
   * @secure
   */
  shiptoListselectedList = (params: RequestParams = {}) =>
    this.request<ShipToListResponse, ProblemDetails | void>({
      path: `/api/shipto/listselected`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoSearchbasedonbasketitemsList
   * @summary Retrieves possible ShipTos for the user to select from based on the basket's items
   * @request GET:/api/shipto/searchbasedonbasketitems
   * @secure
   */
  shiptoSearchbasedonbasketitemsList = (
    query?: {
      Phrase?: string;
      SelectedShipToIds?: ShipToId[];
      selectedShipToIds?: ShipToId[];
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ShipToListResponse, ErrorResult | ProblemDetails | void>({
      path: `/api/shipto/searchbasedonbasketitems`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoSearchfavouriteCreate
   * @request POST:/api/shipto/searchfavourite
   * @secure
   */
  shiptoSearchfavouriteCreate = (data: FavouriteShipToSearchRequest, params: RequestParams = {}) =>
    this.request<ShipToListResponse, ProblemDetails | void>({
      path: `/api/shipto/searchfavourite`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoListselectedfavouriteList
   * @request GET:/api/shipto/listselectedfavourite
   * @secure
   */
  shiptoListselectedfavouriteList = (params: RequestParams = {}) =>
    this.request<ShipToListResponse, ProblemDetails | void>({
      path: `/api/shipto/listselectedfavourite`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoUpdatefavouriteshiptosCreate
   * @request POST:/api/shipto/updatefavouriteshiptos
   * @secure
   */
  shiptoUpdatefavouriteshiptosCreate = (data: UpdateFavouriteShipToRequest, params: RequestParams = {}) =>
    this.request<void, ProblemDetails | void>({
      path: `/api/shipto/updatefavouriteshiptos`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoCustomergroupfilterList
   * @request GET:/api/shipto/customergroupfilter
   * @secure
   */
  shiptoCustomergroupfilterList = (
    query?: {
      searchPhrase?: string;
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<FavouriteShipToFilterResponse, ProblemDetails | void>({
      path: `/api/shipto/customergroupfilter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipTo
   * @name ShiptoSalesrepfilterList
   * @request GET:/api/shipto/salesrepfilter
   * @secure
   */
  shiptoSalesrepfilterList = (
    query?: {
      searchPhrase?: string;
      /**
       * @format int32
       * @default 1
       */
      page?: number;
      /**
       * @format int32
       * @default 10
       */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<FavouriteShipToFilterResponse, ProblemDetails | void>({
      path: `/api/shipto/salesrepfilter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
