import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Badge, Button } from 'components/shared';
import { Icon } from 'components/shared/Icon';
import { InputCheckbox } from 'components/shared/InputCheckbox';
import { LoadingBar } from 'components/shared/LoadingBar';
import styles from './BasketSelectorCard.module.scss';

export interface BasketSelectorCardDataProps {
	id: number;
	date: string;
	isActive: boolean;
	title: string;
	comment: string;
	quantity: number;
	shipTos?: string[];
	otherShipTosCount?: number;
	creatorName?: string;
	brands?: string[];
}
interface BasketSelectorCardProps {
	data?: BasketSelectorCardDataProps;
	disabled?: boolean;
	isLoading?: boolean;
	isInput?: boolean;
	BasketSelectorCardIdLabel?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const BasketSelectorCard: React.FunctionComponent<BasketSelectorCardProps> = (props) => {
	const { data, onClick, onChange, disabled, isLoading, isInput, BasketSelectorCardIdLabel } = props;
	const { data: translations } = useTranslationQuery();

	const [isShipTosExpanded, setIsShipTosExpanded] = useState(false);

	const brandsToDisplay = 3;
	const displayedBrands = data?.brands?.slice(0, brandsToDisplay);
	const otherBrandsCount =
		data?.brands?.length && data.brands.length > brandsToDisplay ? data.brands.length - brandsToDisplay : 0;
	const displayedShipTos = data?.shipTos?.length ? (isShipTosExpanded ? data.shipTos : [data.shipTos[0]]) : [''];

	if (!data) {
		return (
			<div className={styles.skeleton}>
				<LoadingBar width={'25%'} />
				<LoadingBar width={'75%'} />
				<LoadingBar width={'50%'} />
			</div>
		);
	}

	const cardContent = (
		<div className={styles.content}>
			<div className={styles.contentInfo}>
				<div className={styles.infoHeader}>
					<span className={styles.date}>{data.date}</span>
					{isLoading && <div className={classNames(styles.spinner, 'u-spinner')} />}
				</div>

				<div className={styles.infoMain}>
					<span className={styles.title}>{data.title}</span>
					<span className={styles.basketIdLabel}>
						{BasketSelectorCardIdLabel && `${BasketSelectorCardIdLabel}: ${data.id}`}
					</span>

					<div className={styles.brandLabels}>
						{displayedBrands?.map((brandLabel) => (
							<div className={styles.brandLabel} key={`brand-label-${brandLabel}`}>
								{brandLabel}
							</div>
						))}
						{!!otherBrandsCount && <Badge value={otherBrandsCount} />}
					</div>
				</div>

				<div className={styles.infoFooter}>
					{!!data.quantity && (
						<div className={styles.infoFooterItem}>
							<Icon name="basket" size="sm" />
							{data.quantity}
						</div>
					)}
					{data.creatorName && (
						<div className={styles.infoFooterItem}>
							<Icon name="userCircle" size="sm" />
							{data.creatorName}
						</div>
					)}
				</div>
			</div>
			<div className={styles.contentShipTos}>
				<div className={styles.shipToRows}>
					{displayedShipTos.map((shipTo) => (
						<div className={styles.shipTo} key={`ship-to-${shipTo}`}>
							<div className={styles.shipToSection}>
								<Icon name="shipTo" size="md" />
								<span className={styles.shipToName}>{shipTo}</span>
							</div>
						</div>
					))}
				</div>
				{!!data.otherShipTosCount && (
					<>
						{!isShipTosExpanded && <Badge value={data.otherShipTosCount} />}
						<Button
							className={styles.extendShipTos}
							hasOnlyIcon
							size="xs"
							variant="ghost"
							title={isShipTosExpanded ? translations?.shared.collapse : translations?.shared.expand}
							onClick={(e): void => {
								e.stopPropagation();
								e.currentTarget.blur();
								setIsShipTosExpanded(!isShipTosExpanded);
							}}
						>
							<Icon name="chevronDown" size="md" {...(isShipTosExpanded && { rotate: 180 })} />
						</Button>
					</>
				)}
			</div>
		</div>
	);

	if (data.isActive) {
		return <div className={classNames(styles.card, styles.active)}>{cardContent}</div>;
	} else if (isInput) {
		return (
			<InputCheckbox
				className={styles.card}
				checkBoxClassName={styles.cardInput}
				value={data.id}
				checked={false}
				onChange={onChange}
				label={cardContent}
				disabled={disabled}
			/>
		);
	} else {
		return (
			<button
				type="button"
				className={classNames(styles.card, {
					[styles.active]: data.isActive,
				})}
				value={data.id}
				onClick={onClick}
				disabled={disabled}
			>
				{cardContent}
			</button>
		);
	}
};
