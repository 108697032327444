import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import builder from '@builder.io/react';
import { useLayoutQuery } from 'api/layout';
import { parseQueryParameters } from 'api/search';
import { AlertTypes } from 'components/shared';
import { ResourceLocation, RouteResponse } from 'generated/data-contracts';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import 'styles/index.scss';
import ErrorHandler from './ErrorHandler';
import { setRoute } from './routes';

interface RouterProps {
	routingData?: ResourceLocation | RouteResponse;
	routingError?: unknown;
	pageQuery?: string;
	page?: React.ReactElement;
	userMessage?: string;
	isServer?: boolean;
	segmentationId: number;
	isNotFound?: boolean;
	redirect?: (url: string) => void;
}

const Router: React.FunctionComponent<RouterProps> = ({
	routingData,
	routingError,
	pageQuery,
	userMessage,
	page,
	isServer,
	isNotFound,
	redirect,
}) => {
	const [pageData, setPageData] = React.useState(page);
	const [newCmsInitialized, setNewCmsInitialized] = React.useState(false);
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const location = useLocation();
	const { data: layout } = useLayoutQuery();
	const routing = (location.state as ResourceLocation) ?? routingData;
	const dispatch = useDispatch();

	React.useEffect(() => {
		setPageData(undefined);
	}, []);

	React.useEffect(() => {
		if (layout === undefined) return;
		builder.init(layout.cmsSettings.publicApiKey);
		setNewCmsInitialized(true);
	}, [layout]);

	React.useEffect(() => {
		if (userMessage) {
			dispatch(
				appActions.addNotification({
					children: userMessage,
					type: AlertTypes.WARNING,
				}),
			);
		}
	}, [dispatch, userMessage]);

	if (!routing) {
		return null;
	}

	if (!newCmsInitialized) {
		return null;
	}

	if (routingError) {
		return <>{routingError}</>;
	}

	const searchParams = new URLSearchParams(location.search);

	return (
		<ErrorHandler>
			<Suspense fallback={<div>{/* TODO: add fallback markup */}</div>}>
				{page && isServer ? (
					// We know what page from server, render it
					<>{page}</>
				) : (
					// We might know what page. Execute setRoute:
					// render correct component if page is known,
					// otherwise fetch page data in component

					setRoute({
						segmentationId,
						area: routing.area,
						pageType: routing.pageType,
						externalRoute: routing.externalRoute || '',
						isNotFound: isNotFound || false,
						productListPageQuery: parseQueryParameters(routing.productCategoryId, searchParams),
						productFamilyId: routing.productFamilyId,
						pageQuery,
						pageData,
						redirect,
					})
				)}
			</Suspense>
		</ErrorHandler>
	);
};

export default Router;
