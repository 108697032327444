import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Dropdown } from 'components/shared/Dropdown';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared/Tooltip';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './InvoicesDropdown.module.scss';

export interface InvoicesDropdownProps {
	className?: string;
	buttonClassName?: string;
}

export const InvoicesDropdown: React.FunctionComponent<InvoicesDropdownProps> = ({ className, buttonClassName }) => {
	const { data: translations } = useTranslationQuery();
	const {
		orderLineDetails: { invoices },
		isSkeleton,
	} = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;
	const disableInvoiceButton = invoices.length === 0;

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger className={classNames(styles.tooltipTrigger, className)}>
				<Dropdown
					buttonVariant="outline"
					buttonSize="sm"
					buttonLabel={translations?.myOrders.orderHistoryLine.viewInvoices ?? ''}
					disabled={disableInvoiceButton}
					className={classNames(styles.dropdown, {
						[styles.disabled]: disableInvoiceButton,
					})}
					buttonClassName={classNames(buttonClassName, styles.button)}
					contentClassName={styles.dropdownContent}
				>
					<ul className={styles.options}>
						{invoices.map((invoice, index) => (
							<li
								key={`invoices-dropdown-option-${invoice.invoiceNumber}-${index}`}
								className={styles.option}
							>
								<Link className={styles.link} to={invoice.url}>
									{invoice.invoiceNumber}
								</Link>
							</li>
						))}
					</ul>
				</Dropdown>
			</TooltipTrigger>
			{disableInvoiceButton && (
				<TooltipContent className={styles.tooltipText}>
					{translations?.myOrders.orderHistoryLine.viewInvoicesButtonDisabled}
				</TooltipContent>
			)}
		</Tooltip>
	);
};
