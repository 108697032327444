import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useDebounce, useScrollLock } from 'helpers';
import { isEmpty } from 'lodash';
import { queryKeys } from 'api/apiConfig';
import { useLayoutQuery } from 'api/layout';
import { useQuickSearch } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { SearchBar, Button } from 'components/shared';
import { Area, PageType, QuickSearchResponse } from 'generated/data-contracts';
import styles from './QuickSearch.module.scss';
import { QuickSearchResults } from './Results';

interface QuickSearchProps {
	isMobile: boolean;
}

export const QuickSearch: React.FunctionComponent<QuickSearchProps> = (props) => {
	const { data: layout } = useLayoutQuery();

	const { isMobile } = props;

	const { data: translations } = useTranslationQuery();
	const staticLinks = layout?.staticLinks;
	const queryClient = useQueryClient();

	const [inputValue, setInputValue] = React.useState('');
	const [isActive, setIsActive] = React.useState(false);

	const formRef = React.useRef<HTMLFormElement>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();

	const setScrollLock = useScrollLock();

	const locationRef = React.useRef(location);

	const [value, setValue] = React.useState('');

	const { data: quickSearchResponse } = useQuickSearch(value);

	const [results, setResults] = React.useState<QuickSearchResponse | null>(quickSearchResponse || null);

	React.useEffect(() => {
		if (value && !isEmpty(quickSearchResponse)) {
			setResults(quickSearchResponse || null);
		}
	}, [quickSearchResponse, value]);

	const getResults = useDebounce((inputtedValue) => {
		const trimmedValue = inputtedValue.trim();

		setValue(inputtedValue);

		if (!trimmedValue) {
			setResults(null);
			return;
		}
	}, 300);

	const open = (): void => {
		setIsActive(true);

		if (inputValue && !results) {
			getResults(inputValue);
		}
	};

	const close = React.useCallback(() => {
		queryClient.cancelQueries({ queryKey: [queryKeys.product.quickSearch] });

		setIsActive(false);
	}, [queryClient]);

	// Close on page change
	React.useEffect(() => {
		if (locationRef.current !== location) {
			locationRef.current = location;

			setResults(null);
			close();
		}
	}, [location, close]);

	// Activate scrollLock, when opening on mobile
	React.useEffect(() => {
		if (!(isActive && isMobile)) {
			return;
		}

		setScrollLock(true);

		return (): void => {
			setScrollLock(false);
		};
	}, [isActive, isMobile, setScrollLock]);

	// Reset input value on phrase change
	React.useEffect(() => {
		const phrase = searchParams.get('phrase');

		setInputValue((prevValue) => {
			if (prevValue !== phrase) {
				return phrase || '';
			} else {
				return prevValue;
			}
		});
	}, [searchParams]);

	// Close when tabbing out
	const handleBlur = (event): void => {
		const { relatedTarget } = event;

		if (isActive && relatedTarget && formRef.current) {
			if (!formRef.current.contains(relatedTarget)) {
				close();
			}
		}
	};

	const handleChange = (event): void => {
		const { value: inputtedValue } = event.target;

		setInputValue(inputtedValue);
		getResults(inputtedValue);
	};

	const handleClear = (): void => {
		setInputValue('');
		getResults('');

		const url = staticLinks?.find((item) => item.pageType === PageType.Search)?.url;

		navigate(
			{
				pathname: url,
				search: `?phrase=`,
			},
			{
				state: {
					area: Area.StaticPages,
					pageType: PageType.Search,
				},
			},
		);
	};

	const handleSubmit = (event): void => {
		event.preventDefault();

		const encodedSearchPhrase = encodeURIComponent(inputValue.trim());

		if (inputRef.current) {
			inputRef.current.blur();
		}

		const url = staticLinks?.find((item) => item.pageType === PageType.Search)?.url;

		navigate(
			{
				pathname: url,
				search: `?phrase=${encodedSearchPhrase}`,
			},
			{
				state: {
					area: Area.StaticPages,
					pageType: PageType.Search,
				},
			},
		);
	};

	const hasResults =
		(results?.searchSuggestions && results.searchSuggestions.length > 0) ||
		(results?.products && results.products.length > 0);

	return (
		<div className={styles.wrapper}>
			<form
				className={classNames(styles.form, {
					[styles.active]: isActive,
				})}
				onSubmit={handleSubmit}
				onBlur={handleBlur}
				ref={formRef}
			>
				<header className={styles.header}>
					<SearchBar
						value={inputValue}
						placeholder={translations?.shared.searchPlaceholder}
						onChange={handleChange}
						onClear={handleClear}
						onFocus={open}
						ref={inputRef}
					/>

					{isActive && (
						<Button className={styles.close} size={'sm'} variant={'ghost'} onClick={close}>
							{translations?.shared.close}
						</Button>
					)}
				</header>

				{isActive && (
					<>
						<Button hasNoStyles className={styles.overlay} onClick={close} />

						{hasResults && <QuickSearchResults results={results} />}
					</>
				)}
			</form>
		</div>
	);
};
