import React from 'react';
import classNames from 'classnames';
import { InputCheckbox } from 'components/shared/InputCheckbox';
import { FilterResponse, FilterValueResponse } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './CheckboxFilter.module.scss';

interface CheckboxFilterProps extends FilterResponse {
	checkboxClassName?: string;
	onCheckboxChange?: (e) => void;
	fullScreen?: boolean;
	uniqueFilterId?: string;
}

export const CheckboxFilter: React.FunctionComponent<CheckboxFilterProps> = ({
	name,
	values,
	checkboxClassName,
	onCheckboxChange,
	uniqueFilterId,
	fullScreen,
}) => {
	const [filteredValues, setFilteredValues] = React.useState<FilterValueResponse[]>(values || []);

	const { isMobile } = useViewportSize();

	// Reset search & filteredValues, when values change
	React.useEffect(() => {
		if (!values) return;

		setFilteredValues(values);
	}, [values]);

	const renderLabel = (item: FilterValueResponse): React.ReactNode => (
		<div className={styles.checkboxLabel}>
			<span>{item.label}</span>
			<span className={styles.hitCount}>({item.hitCount})</span>
		</div>
	);

	return (
		<div
			className={classNames(styles.scrollWrapper, 'u-scroll-wrapper-y', {
				[styles.isFullScreen]: fullScreen,
			})}
		>
			<div
				className={classNames(styles.innerWrapper, {
					[styles.desktopLimitHeight]: !isMobile,
				})}
			>
				{filteredValues?.map((item, i) => (
					<InputCheckbox
						className={checkboxClassName}
						label={renderLabel(item)}
						id={name + item.value + uniqueFilterId}
						name={item.value || ''}
						checked={item.isSelected || false}
						onChange={(e): void => {
							if (onCheckboxChange) {
								onCheckboxChange(e);
							}
						}}
						key={name + item.value + i}
					/>
				))}
			</div>
		</div>
	);
};
