import React from 'react';
import { BuilderComponent } from '@builder.io/react';
import { useCmsContentQuery } from 'api/cms';
import { CmsSettingsResponse } from 'generated/data-contracts';
import { AudienceTargetingResponse } from 'generated/data-contracts';
import styles from './CompanyInfo.module.scss';

interface CompanyInfoProps {
	cmsSettings?: CmsSettingsResponse;
	audience?: AudienceTargetingResponse;
}

const CompanyInfo: React.FC<CompanyInfoProps> = (props) => {
	const companyInfoModelName = props.cmsSettings?.footerUsp ?? '';
	const { data: companyInfo } = useCmsContentQuery('', companyInfoModelName, props.audience);
	if (!companyInfo) {
		return null;
	}
	return (
		<div className={styles.container}>
			<BuilderComponent model={companyInfoModelName} content={companyInfo} />
		</div>
	);
};

export default CompanyInfo;
