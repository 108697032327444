import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { useQuickSearch } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon, Link, ProductCard } from 'components/shared';
import { Area, PageType, QuickSearchResponse } from 'generated/data-contracts';
import styles from './Results.module.scss';

interface QuickSearchResultsProps {
	results: QuickSearchResponse;
}

interface CacheData {
	key: string;
	value: React.SetStateAction<QuickSearchResponse>;
}

export const QuickSearchResults: React.FunctionComponent<QuickSearchResultsProps> = ({ results }) => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	const staticLinks = layout?.staticLinks;

	const [hoverResults, setHoverResults] = React.useState<QuickSearchResponse>(results);
	const [isExpanded, setIsExpanded] = React.useState(false);
	const [cache, setCache] = React.useState<CacheData[]>([]);

	const [value, setValue] = React.useState('');

	const { data: quickSearchResponse, isFetching } = useQuickSearch(value);

	const timerRef = React.useRef<NodeJS.Timeout>();

	React.useEffect(() => {
		if (value && !isEmpty(quickSearchResponse)) {
			setHoverResults(quickSearchResponse);

			setCache((prevCache) => [...prevCache, { key: value, value: quickSearchResponse }]);
		}
	}, [quickSearchResponse, value]);

	React.useEffect(() => {
		setHoverResults(results);
		setIsExpanded(false);

		setCache([]);
	}, [results]);

	const getSearchRoute = (phrase: string): { pathname: string; search: string } => {
		const url = staticLinks?.find((item) => item.pageType === PageType.Search)?.url || '';

		return {
			pathname: url,
			search: `?phrase=${encodeURIComponent(phrase)}`,
		};
	};

	const getProducts = (phrase: string): void => {
		const cacheData = cache.find((item) => item.key === phrase);

		if (cacheData) {
			setHoverResults(cacheData.value);
			return;
		}
		setValue(phrase);
	};

	const handleMouseEnter = (event: React.MouseEvent<HTMLAnchorElement>): void => {
		const {
			dataset: { phrase },
		} = event.currentTarget;

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		if (!phrase || phrase === hoverResults.phrase) {
			return;
		}

		timerRef.current = setTimeout(() => {
			getProducts(phrase);
		}, 300);
	};

	const handleMouseLeave = (): void => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	};

	const expandSuggestions = (): void => {
		setIsExpanded(true);
	};

	return (
		<div className={styles.wrapper}>
			{results.searchSuggestions && (
				<div className={classNames(styles.column, styles.hiddenColumn)}>
					<div className={styles.header}>
						<h3 className={styles.heading}>{translations?.shared.quicksearch.suggestions}</h3>
					</div>

					<ul
						className={classNames(styles.list, {
							[styles.expanded]: isExpanded,
						})}
						onMouseLeave={handleMouseLeave}
					>
						{results.searchSuggestions.map((item, i) => (
							<li key={i}>
								<Link
									className={classNames(styles.itemLink, {
										[styles.selected]: item === hoverResults.phrase,
									})}
									to={getSearchRoute(item)}
									state={{ area: Area.StaticPages, pageType: PageType.Search }}
									data-phrase={item}
									onMouseEnter={handleMouseEnter}
								>
									{item}
									<Icon name={'chevronDown'} rotate={270} size={'md'} />
								</Link>
							</li>
						))}
					</ul>

					{!isExpanded && results.searchSuggestions.length > 4 && (
						<Button
							className={styles.showMore}
							size={'sm'}
							variant={'secondary'}
							onClick={expandSuggestions}
						>
							{translations?.shared.quicksearch.showAll}
						</Button>
					)}
				</div>
			)}

			{hoverResults.products && (
				<div className={styles.column}>
					<div className={styles.header}>
						<div className={styles.headerInfo}>
							<h3 className={styles.heading}>{translations?.shared.quicksearch.products}</h3>
							<span className={classNames(styles.count, 'u-text-color-default-subdued')}>
								{hoverResults.products.length} of {hoverResults.totalHits}
							</span>
						</div>
						{!isFetching && hoverResults.totalHits > 4 && (
							<Link
								className={styles.showAll}
								to={getSearchRoute(hoverResults.phrase || '')}
								state={{ area: Area.StaticPages, pageType: PageType.Search }}
								isTextLink
							>
								{translations?.shared.quicksearch.showAll}
							</Link>
						)}
					</div>

					<ul className={styles.grid}>
						{isFetching ? (
							<>
								{Array.from(Array(2)).map((_, i) => (
									<li key={i} className="u-flex">
										<ProductCard isSkeleton />
									</li>
								))}
							</>
						) : (
							<>
								{hoverResults.products.map((item) => (
									<li key={item.id} className="u-flex">
										<ProductCard product={item} />
									</li>
								))}
							</>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};
