import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useImageLookup } from 'api/image';
import DefaultImage from 'assets/images/defaultImage.jpg';
import { Image } from 'components/shared';
import { ImageLookupRequestItem } from 'generated/data-contracts';
import styles from './DAMImage.module.scss';

interface ImageResponse {
	size100: { url: string };
	size450: { url: string };
	size1800: { url: string };
	altText: string;
}

const fallBackImage: ImageResponse = {
	size100: { url: DefaultImage },
	size450: { url: DefaultImage },
	size1800: { url: DefaultImage },
	altText: '',
};

interface DAMImageProps {
	children?: React.ReactNode;
	image?: ImageLookupRequestItem;
	altText?: string;
	fitContent?: boolean;
	imageFit?: string;
	imagePosition?: { x?: number; y?: number };
	aspectRatio?: number;
	attributes?: React.HTMLAttributes<HTMLDivElement>;
}

/**
 * CMS Image component, used for creating Image. It overrides Builder's default
 * image component, but source uses DAM images.
 * */
export const DAMImage: React.FunctionComponent<DAMImageProps> = ({
	children,
	image,
	altText,
	imageFit,
	imagePosition,
	fitContent,
	aspectRatio,
	attributes,
}) => {
	const images = image
		? [{ fileId: image.fileId, croppingId: image.croppingId ?? null, filePath: image.filePath ?? null }]
		: [];
	const { data: damImages } = useImageLookup(images);
	const damImage = damImages?.images?.[0] ?? fallBackImage;

	const { className: builderClassNames, ...restOfAttributes } = attributes || {};

	const renderChildren = (): React.ReactNode => {
		if (fitContent) {
			return children;
		}
		return <div className={styles.childrenContainer}>{children}</div>;
	};

	return (
		<div
			className={classNames(
				styles.wrapper,
				{ [styles.fitContent]: children },
				{ [styles.hasAspectRatio]: aspectRatio && aspectRatio > 0 },
				builderClassNames,
			)}
			{...restOfAttributes}
			style={
				{
					'--imageFit': imageFit ? imageFit : 'cover',
					'--imagePosX': imagePosition?.x !== undefined ? `${imagePosition.x}%` : 'center',
					'--imagePosY': imagePosition?.y !== undefined ? `${imagePosition.y}%` : 'center',
					'--aspectRatio': aspectRatio && aspectRatio > 0 ? aspectRatio : undefined,
				} as React.CSSProperties
			}
		>
			{damImage && (
				<>
					<Image
						className={styles.image}
						desktopSrc={damImage.size1800.url}
						desktopSrc2x={damImage.size1800.url}
						mobileSrc={damImage.size450.url}
						altText={altText || damImage.altText || ''}
						data-aspect-ratio={aspectRatio === 0 ? '0' : undefined}
					/>
					{(!fitContent || isEmpty(children)) && aspectRatio !== 0 && (
						<div className={styles.aspectRatio}></div>
					)}
				</>
			)}
			{children && renderChildren()}
		</div>
	);
};
