import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface QuantityProps {}

export const Quantity: React.FunctionComponent<QuantityProps> = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	return <OrderDetailCell title={translations?.myOrders.orderDetails?.quantity} value={orderLineDetails.quantity} />;
};
