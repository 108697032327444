import { useEffect } from 'react';
import { wrap } from 'comlink';
import { useAsync } from 'components/shared/hooks/useAsync';
import type { WorkerType } from '../workers/pdf.worker';
import Worker from '../workers/pdf.worker?worker';


export const pdfWorker = wrap<WorkerType>(new Worker());
export const useRenderPDF = (
	props: Parameters<WorkerType['renderPDf']>[0],
) => {
	const {
		value: url,
		loading,
		error,
		call,
	} = useAsync(
		async ({
			...rest
		}: Parameters<WorkerType['renderPDf']>[0]) => {
			return pdfWorker.renderPDf(rest);
		},
		[props],
		{
			manual: true,
		},
	);
	useEffect(() => (url ? () => URL.revokeObjectURL(url) : undefined), [url]);
	return { url, loading, error, call };
};
