import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { FilterDropdown, PriceQueryProps } from 'components/shared/Filters';
import { setActivePriceCount, setActiveCount } from 'components/shared/Filters/helpers';
import {
	ActiveFilterResponse,
	FilterRequest,
	FilterResponse,
	FilterType,
	PriceSliderResponse,
} from 'generated/data-contracts';
import { Button } from '../Button';
import { Icon } from '../Icon';
import styles from './FilterList.module.scss';

export interface FilterListProps {
	defaultNumberOfShownFilters?: number;
	filterValues: FilterResponse[];
	activeFilters?: ActiveFilterResponse[];
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	priceFilter?: PriceQueryProps;
	setPriceFilter?: Dispatch<SetStateAction<PriceQueryProps>>;
	uniqueFilterId?: string;
	priceSliderValues?: PriceSliderResponse;
	changePriceFilter?: () => void;
	changeFilter: (id: string) => void;
}

export const FilterList: React.FunctionComponent<FilterListProps> = ({
	defaultNumberOfShownFilters = 3,
	filterValues,
	priceSliderValues,
	priceFilter,
	setPriceFilter,
	toBeSet,
	toBeRemoved,
	setToBeRemovedFilters,
	setToBeSetFilters,
	uniqueFilterId,
	changeFilter,
	changePriceFilter,
	activeFilters,
}) => {
	const amountOfFilters = isEmpty(activeFilters) ? defaultNumberOfShownFilters : filterValues.length;
	const [numberOfShownFilters, setNumberOfShownFilters] = React.useState(amountOfFilters);
	const limitedFilterValues = filterValues.slice(0, numberOfShownFilters);

	useEffect(() => {
		if (numberOfShownFilters !== defaultNumberOfShownFilters && numberOfShownFilters !== filterValues.length) {
			setNumberOfShownFilters(amountOfFilters);
		}
	}, [defaultNumberOfShownFilters, filterValues, numberOfShownFilters, amountOfFilters]);

	const { data: translations } = useTranslationQuery();

	const showAllFiltersButton =
		filterValues.length > 2 &&
		numberOfShownFilters <= defaultNumberOfShownFilters &&
		filterValues.length !== numberOfShownFilters;

	return (
		<div className={styles.desktopFilters}>
			{!isEmpty(limitedFilterValues) && (
				<section className={styles.filterSection}>
					{limitedFilterValues.map((filter) => {
						if (!filter && priceSliderValues?.maxPrice && priceSliderValues?.maxPrice > 0) {
							return (
								<FilterDropdown
									key={'price-filter'}
									uniqueFilterId={uniqueFilterId}
									type={FilterType.Number}
									name={translations?.productList?.filterDisplayName?.price || ''}
									id={'price'}
									priceSliderValues={priceSliderValues}
									activeCount={setActivePriceCount(priceSliderValues)}
									priceFilter={priceFilter}
									setPriceFilter={setPriceFilter}
									onPriceSubmit={changePriceFilter}
								/>
							);
						}

						if (!filter) return;

						return (
							<FilterDropdown
								{...filter}
								uniqueFilterId={uniqueFilterId}
								activeCount={setActiveCount(filter)}
								onSubmit={changeFilter}
								key={filter.id}
								toBeSet={toBeSet}
								toBeRemoved={toBeRemoved}
								setToBeSetFilters={setToBeSetFilters}
								setToBeRemovedFilters={setToBeRemovedFilters}
							/>
						);
					})}

					{showAllFiltersButton && (
						<Button
							className={styles.allFiltersButton}
							size={'sm'}
							variant={'secondary'}
							onClick={(): void => {
								setNumberOfShownFilters(filterValues.length);
							}}
						>
							<Icon name={'filters'} size={'sm'} />
							<span>{translations?.productList?.filters?.filtersButton}</span>
						</Button>
					)}
				</section>
			)}
		</div>
	);
};
