import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface WholesalePriceProps {}

export const WholesalePrice: React.FunctionComponent<WholesalePriceProps> = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	return (
		<OrderDetailCell title={translations?.myOrders.orderDetails?.price} value={orderLineDetails.wholesalePrice} />
	);
};
