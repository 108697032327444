import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonProps } from 'components/shared/Button';
import { DrawerBody, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import { Dropdown } from 'components/shared/Dropdown';
import { Icon } from 'components/shared/Icon';
import { OptionProps } from 'components/shared/Select';
import { SortDirection } from 'generated/data-contracts';
import styles from './Sorting.module.scss';

export interface SortingResponse {
	sortBy: string;
	sortDirection: SortDirection;
	displayName: string;
}
interface SortingProps {
	currentSorting?: SortingResponse;
	activeLevel?: string;
	handleClose?: () => void;
	handleBack?: () => void;
	handleLevelActivation?: (levelId: string) => void;
	setSorting: (sortBy: string, sortDirection: SortDirection) => void;
	isMobile?: boolean;

	sorting?: SortingResponse[];
	buttonVariant?: ButtonProps['variant'];
	buttonSize?: ButtonProps['size'];
}

export const Sorting: React.FunctionComponent<SortingProps> = ({
	currentSorting,
	activeLevel,
	handleClose,
	handleBack,
	handleLevelActivation,
	setSorting,
	isMobile,

	sorting = [],
	buttonVariant = 'secondary',
	buttonSize = 'sm',
}) => {
	const { data: translations } = useTranslationQuery();
	const [forceClosed, setForceClosed] = React.useState(false);

	const [selectedSortingName, setSelectedSortingName] = React.useState(currentSorting?.displayName);

	const sortId = 'sortFilter';

	React.useEffect(() => {
		setForceClosed(false);
	}, [currentSorting?.sortBy]);

	React.useEffect(() => {
		setSelectedSortingName(currentSorting?.displayName);
	}, [currentSorting?.displayName]);

	const handleChange = (value, displayName): void => {
		setSorting(value.substring(0, value.indexOf(',')), value.substring(value.indexOf(',') + 1));
		setSelectedSortingName(displayName);
		setForceClosed(true);
	};

	const mappedOptions: OptionProps[] = React.useMemo(
		() =>
			sorting.map((option) => {
				return {
					displayName: option.displayName || '',
					value: `${option.sortBy},${option.sortDirection}`,
				};
			}),
		[sorting],
	);

	return (
		<>
			{!isMobile ? (
				<Dropdown
					className={styles.dropDownWrapper}
					contentClassName={classNames(styles.dropdownContent, { [styles.mobileDropDownContent]: isMobile })}
					buttonClassName={classNames(styles.dropdownButton, 'u-px-lg u-py-xs', {
						[styles.mobileDropDownButton]: isMobile,
					})}
					forceClosed={forceClosed}
					alignContentRight
					buttonVariant={buttonVariant}
					buttonSize={buttonSize}
					buttonLabel={currentSorting?.displayName ?? translations?.productList.sortBy ?? ''}
				>
					<div className={styles.optionsWrapper}>
						{!isMobile && <legend className={styles.legend}>{translations?.productList.sortBy}</legend>}
						<div className={styles.options}>
							{mappedOptions.map((option, index) => (
								<Button
									hasNoStyles
									key={`${option.value}-${index}`}
									className={styles.option}
									onClick={(): void => handleChange(option.value, option.displayName)}
								>
									<span>{option.displayName}</span>
									{option.displayName === selectedSortingName && (
										<Icon name={'checkMark'} size={'md'} />
									)}
								</Button>
							))}
						</div>
					</div>
				</Dropdown>
			) : (
				handleBack &&
				handleClose &&
				handleLevelActivation && (
					<>
						<Button
							hasNoStyles
							className={styles.mobileButton}
							onClick={(): void => handleLevelActivation(sortId)}
						>
							<div className={styles.mobileSortingInfo}>
								<span className={styles.mobileSortByLabel}>{translations?.productList.sortBy}</span>
								<span>{selectedSortingName}</span>
							</div>
							<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
						</Button>
						{activeLevel === sortId && (
							<DrawerLevel isActive={activeLevel === sortId} handleClose={handleClose}>
								<DrawerHeaderWBackButton
									title={translations?.productList.sortBy}
									handleBack={handleBack}
									handleClose={handleClose}
								/>
								<DrawerBody>
									{mappedOptions.map((option, index) => (
										<Button
											hasNoStyles
											key={`${option.value}-${index}`}
											className={styles.mobileOption}
											onClick={(): void => handleChange(option.value, option.displayName)}
										>
											<span>{option.displayName}</span>
											{option.displayName === selectedSortingName && (
												<Icon name={'checkMark'} size={'md'} />
											)}
										</Button>
									))}
								</DrawerBody>
							</DrawerLevel>
						)}
					</>
				)
			)}
		</>
	);
};
