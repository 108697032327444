import { Builder } from '@builder.io/react';
import { ProductSlider } from '.';
import { getSharedProductsFilters } from '../../shared/ProductsFilters';

Builder.registerComponent(ProductSlider, {
	name: 'ProductSlider',
	image: new URL('/dist/images/cms/productSlider.svg', window.location.origin).href,
	inputs: [
		{
			name: 'numberOfProductsShown',
			type: 'number',
			helperText:
				'Number of products shown in the slider, by default 2 on mobile, 4 on desktop, as many as can fit on large screens',
		},
		...getSharedProductsFilters('ProductSlider'),

		{
			name: 'infiniteScroll',
			type: 'boolean',
			helperText:
				'If the slider should be able to scroll infinitely. \n ' +
				'This means that when you reach the end of the slider, it will start at the middle of the slider',
		},
		{
			name: 'autoScroll',
			type: 'boolean',
			helperText: 'If the slider should automatically scroll through the products',
			showIf: (options): boolean => options.get('infiniteScroll') === true,
		},
		{
			name: 'autoScrollDelay',
			type: 'number',
			helperText: 'How long the slider should wait before scrolling to the next slide, in milliseconds',
			showIf: (options): boolean => options.get('autoScroll') === true,
		},
	],
});
