import React from 'react';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import { PDFDownloadResponse, PDFDownloadWithSummaryResponse } from 'generated/data-contracts';
import { formatTranslation } from '../../../../helpers/stringHelpers';
import { pdfWorker } from '../helpers/useRenderPdf';
import { appActions } from 'store/actions/appActions';

interface GeneratePdfPayload {
	title: string;
	id?: string;
	data: PDFDownloadWithSummaryResponse | PDFDownloadResponse;
}

interface GenerationJob {
	id: string;
	title: string;
	loading: boolean;
	startTime: number;
	finished: boolean;
	error?: string;
	url?: string;
}

interface UsePDFExportContextReturnType {
	generatePdf: (payload: GeneratePdfPayload) => Promise<void>;
	generationJobsQueue: GenerationJob[];
}

const usePDFExportContextState = (): UsePDFExportContextReturnType => {
	const [generationJobsQueue, setGenerationJobsQueue] = React.useState<GenerationJob[]>([]);
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	React.useEffect(() => {
		const beforeUnload = (event: BeforeUnloadEvent) => {
			if (generationJobsQueue.length === 0) return;
			event.returnValue =
				'We are currently generating PDFs. Closing the page will cancel that progress. Are you sure you want to close the page?';
		};

		window.addEventListener('beforeunload', beforeUnload);
		return () => {
			window.removeEventListener('beforeunload', beforeUnload);
		};
	}, [generationJobsQueue]);

	const generatePdf = React.useCallback(
		async ({ title: _title, data, id: _id }: GeneratePdfPayload) => {
			const id = _id ?? uniqueId('pdf-');
			const title = `${_title}.pdf`;
			setGenerationJobsQueue((prev) => [
				...prev,
				{
					id,
					title,
					finished: false,
					startTime: Date.now(),
					loading: true,
				},
			]);
			try {
				dispatch(
					appActions.addNotification({
						children: (
							<p>
								{translations?.shared.exports.startingPdfGeneration}
								<br />
								{translations?.shared.exports.pleaseKeepPageOpen}
							</p>
						),
						type: AlertTypes.SUCCESS,
					}),
				);
				const url = await pdfWorker.renderPDf({
					payload: data,
					translations,
					title,
				});
				setGenerationJobsQueue((prev) =>
					prev.map((job) =>
						job.id === id
							? {
									...job,
									url,
							  }
							: job,
					),
				);
			} catch (error) {
				setGenerationJobsQueue((prev) =>
					prev.map((job) =>
						job.id === id
							? {
									...job,
									error: JSON.stringify(error),
							  }
							: job,
					),
				);
			} finally {
				setGenerationJobsQueue((prev) =>
					prev.map((job) =>
						job.id === id
							? {
									...job,
									loading: false,
									finished: true,
							  }
							: job,
					),
				);
			}
		},
		[dispatch, translations],
	);

	const onSuccess = React.useCallback(
		({ title, url }: GenerationJob) => {
			if (!url) {
				return;
			}
			const a = document.createElement('a');
			a.href = url;
			a.download = title;
			a.click();
			dispatch(
				appActions.addNotification({
					children: (
						<p>{formatTranslation(translations?.shared.exports.pdfGenerationSuccess, { 0: title })}</p>
					),
					type: AlertTypes.SUCCESS,
				}),
			);
		},
		[dispatch, translations],
	);

	const onError = React.useCallback(
		({ title, error }: GenerationJob) => {
			if (!error) {
				return;
			}

			dispatch(
				appActions.addNotification({
					children: (
						<p>{formatTranslation(translations?.shared.exports.pdfGenerationFailed, { 0: title })}</p>
					),
					type: AlertTypes.DANGER,
				}),
			);
		},
		[dispatch, translations?.shared.exports.pdfGenerationFailed],
	);

	React.useEffect(() => {
		if (generationJobsQueue.length === 0) return;
		generationJobsQueue.forEach((job) => {
			if (job.finished) {
				onSuccess(job);
				onError(job);
			}
		});
		setGenerationJobsQueue((prev) => {
			if (prev.filter((job) => job.finished).length === 0) {
				return prev;
			}
			return prev.filter((job) => !job.finished);
		});
	}, [generationJobsQueue, onError, onSuccess]);

	return {
		generatePdf,
		generationJobsQueue,
	};
};

const PDFExportContextContext = React.createContext<UsePDFExportContextReturnType | null>(null);

export const PDFExportContextProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
	const value = usePDFExportContextState();
	return <PDFExportContextContext.Provider value={value}>{children}</PDFExportContextContext.Provider>;
};

export const usePDFExportContext = (): UsePDFExportContextReturnType => {
	const context = React.useContext(PDFExportContextContext);

	if (!context) {
		throw new Error('usePDFExportContext must be used within a PDFExportContextProvider');
	}

	return context;
};
