import type * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (value): any => {
		refs.forEach((ref) => {
			if (typeof ref === "function") {
				ref(value);
			} else if (ref != null) {
				(ref as React.MutableRefObject<T | null>).current = value;
			}
		});
	};
}
