import React, { FC, ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Drawer, DrawerHeader, DrawerLevel, Icon, Link, Logo } from 'components/shared';
import { Area, BrandResponse, PageType, SubBrandResponse } from 'generated/data-contracts';
import BrandGroup from '../BrandGroup';
import globalStyles from '../BrandSelector.module.scss';
import BrandSelectSectionMobile from './BrandSelectSectionMobile';
import styles from './BrandSelectorMobile.module.scss';

export interface BrandSelectorMobileProps {
	brands: BrandResponse[];
	isDrawer?: boolean;
	accountSelectorUrl?: string;
	onChooseBrand: (brand: BrandResponse | SubBrandResponse) => void;
}

const BrandSelectorMobile: FC<BrandSelectorMobileProps> = ({
	brands,
	isDrawer = false,
	accountSelectorUrl,
	onChooseBrand,
}) => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	const staticLinks = layout?.staticLinks;

	let accountUrl = accountSelectorUrl;

	if (!accountUrl && staticLinks) {
		accountUrl = staticLinks.find((item) => item.pageType === PageType.AccountSelector)?.url;
	}

	const [brandGroups, setBrandGroups] = useState<Record<number, BrandResponse[]>>({});
	const [brandGroupButtonHeight, setBrandGroupButtonHeight] = useState<string>('');
	const [selectedBrand, setSelectedBrand] = useState<BrandResponse | null>(null);
	const [brandGroupColumnSize, SetBrandGroupColumnSize] = useState<number>(2);

	const brandsLogoHeight = '6rem';
	const logoSize = '7.5rem';
	React.useEffect(() => {
		const newBrandGroups: Record<number, BrandResponse[]> = {};
		if (brands.length < 4) {
			SetBrandGroupColumnSize(1);
			setBrandGroupButtonHeight(styles.brandGroupButtonHeightSmall);
		} else {
			SetBrandGroupColumnSize(2);
			setBrandGroupButtonHeight(styles.brandGroupButtonHeightNormal);
		}
		for (let i = 0; i < brands.length; i += brandGroupColumnSize) {
			const brandChunk = brands.slice(i, i + brandGroupColumnSize);

			newBrandGroups[i] = brandChunk;
		}

		setBrandGroups(newBrandGroups);
	}, [brands, brandGroupColumnSize]);

	const onBrandClick = (brand: BrandResponse): void => {
		if (!brand.subBrands.length) {
			onChooseBrand(brand);
			return;
		}

		if (!brand.active && brand.subBrands.length === 1) {
			onChooseBrand(brand.subBrands[0]);
			return;
		}

		setSelectedBrand(brand);
	};

	const onCloseDrawer = (): void => {
		setSelectedBrand(null);
	};

	const renderSubBrands = (brand): ReactElement => {
		if (isDrawer)
			return (
				<DrawerLevel direction="Right" isActive={!!selectedBrand}>
					<DrawerHeader logo={brand.brandId} handleClose={onCloseDrawer} />
					<BrandSelectSectionMobile
						brand={brand}
						brandLogoHeight={brandsLogoHeight}
						onChooseBrand={onChooseBrand}
						isDrawer={isDrawer}
					/>
				</DrawerLevel>
			);
		return (
			<Drawer direction="Right" onClose={onCloseDrawer}>
				<DrawerHeader logo={brand.brandId} handleClose={onCloseDrawer} />
				<BrandSelectSectionMobile
					brand={brand}
					brandLogoHeight={brandsLogoHeight}
					onChooseBrand={onChooseBrand}
					isDrawer={isDrawer}
				/>
			</Drawer>
		);
	};

	const route = {
		area: Area.StaticPages,
		pageType: PageType.AccountSelector,
		editAccounts: true,
	};

	return (
		<>
			{!isDrawer && (
				<header className={classNames(globalStyles.header, styles.headerPadding)}>
					<Link to={accountUrl} state={route} className={styles.zIndex}>
						<Icon name={'chevronDown'} rotate={90} size="md" />
					</Link>
					<div className={styles.logoWrapper}>
						<Logo name="B_BS" width={'9.375rem'} height={'1.5rem'} />
					</div>
				</header>
			)}

			<div
				className={classNames(globalStyles.bodyWrapper, styles.noBottomPadding, {
					['u-container-lg u-py-lg']: isDrawer,
				})}
			>
				<ul className={classNames({ ['u-container-lg']: !isDrawer })}>
					{!isDrawer && (
						<li className={classNames(globalStyles.bodyHeader, styles.bodyHeaderPadding)}>
							{translations?.brandSelector.chooseBrand}
						</li>
					)}
					{brandGroups && (
						<li>
							{Object.entries(brandGroups).map(([brandGroupId, brandGroup]) => {
								return (
									<BrandGroup
										key={`${brandGroupId}`}
										brandGroup={brandGroup}
										brandGroupButtonClassname={brandGroupButtonHeight}
										brandGroupId={brandGroupId}
										columnSize={brandGroupColumnSize}
										logoSize={logoSize}
										logoHeight={brandsLogoHeight}
										onBrandClick={(_, brand): void => onBrandClick(brand)}
									></BrandGroup>
								);
							})}

							{selectedBrand && renderSubBrands(selectedBrand)}
						</li>
					)}
				</ul>

				<div className={classNames(globalStyles.manageAccountsWrapper, globalStyles.manageAccountsPosition)}>
					<span>{translations?.brandSelector.missingBrand}</span>
					<Link className={globalStyles.link} to={accountUrl} state={route}>
						{translations?.brandSelector.manageAccounts}
					</Link>
				</div>
			</div>
		</>
	);
};

export default BrandSelectorMobile;
