import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { Link } from 'components/shared/Link';
import { ProductCard } from 'components/shared/ProductCard';
import { ProductItemResponse } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import NewSlider from '../NewSlider';
import styles from './ProductSlider.module.scss';

// coming from old cms
export interface ProductSliderInformation {
	headline?: string | null;
	urlTitle?: string | null;
	url?: string | null;
}

interface ProductSliderProps {
	products?: ProductItemResponse[] | null;
	sliderInformation?: ProductSliderInformation | null;
	numberOfProductsShown?: number;
	infiniteScroll?: boolean;
	autoScroll?: boolean;
	autoScrollDelay?: number;
}

/**
 * ProductSlider component, used for showing product cards next to each other.
 * Initiates a slider, when enough products passed.
 */
export const ProductSlider: React.FunctionComponent<ProductSliderProps> = ({
	sliderInformation,
	products,
	numberOfProductsShown: _numberOfProductsShownFromProps,
	infiniteScroll,
	autoScroll,
	autoScrollDelay,
}) => {
	const id = useRef(uniqueId('product-slider')).current;
	const size = useViewportSize();
	const { numberOfProductsShown, cardWidth, isNavigationShown } = useMemo(() => {
		switch (size.size) {
			case 'xs':
				return { numberOfProductsShown: 2, isNavigationShown: false };
			case 'sm':
			case 'md':
				return { numberOfProductsShown: 4, isNavigationShown: true };
			case 'lg':
			case 'xl':
			default:
				return { cardWidth: '18rem', isNavigationShown: true };
		}
	}, [size]);

	const shownProducts = products
		? products
		: Array.from({ length: numberOfProductsShown ?? 1 }).map((_v, i) => ({
				isSkeleton: true,
				isOnFavouriteList: false,
				id: `skeleton-${i}`,
		  }));

	if (shownProducts.length < 1) {
		return (
			<div className={classNames(styles.cardWrapper, 'u-grid u-grid-cols-custom')}>
				<ProductCard isSkeleton isHidden />
			</div>
		);
	}
	if ((products ?? []).length > 3) {
		return (
			<>
				<div className={styles.sliderInfoWrapper}>
					{sliderInformation?.headline && <h3>{sliderInformation?.headline}</h3>}
					{sliderInformation?.url && (
						<Link
							className={styles.link}
							linkUrl={sliderInformation?.url}
							target="_self"
							hasExternalIconHidden
						>
							{sliderInformation?.urlTitle}
						</Link>
					)}
				</div>

				<NewSlider
					showNavigation={isNavigationShown}
					numberOfItemsShown={_numberOfProductsShownFromProps ?? numberOfProductsShown}
					slideWidth={_numberOfProductsShownFromProps ? undefined : cardWidth}
					arrowsClassName={styles.productSliderArrows}
					arrowContainerClassName={styles.productSliderArrowsContainer}
					infiniteScroll={infiniteScroll}
					autoScroll={autoScroll}
					autoScrollDelay={autoScrollDelay}
				>
					{products?.map((product) => <ProductCard product={product} key={`${id}-${product.id}`} />)}
				</NewSlider>
			</>
		);
	}

	return (
		<div className={classNames(styles.cardWrapper, 'u-grid u-grid-cols-custom')}>
			{shownProducts.map((product) => (
				<ProductCard product={product} key={`${id}-${product.id}`} />
			))}
		</div>
	);
};
