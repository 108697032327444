import { setHeaders } from 'api/apiConfig';
import { Recommendation as RecommendationApi } from 'generated/Recommendation';
import {
	FilterRequest,
	ProductFilter,
	ProductListResponse,
	RelewiseRecommendationType,
} from 'generated/data-contracts';

export interface RelewiseProductRecommendationQuery {
	amount: number;
	type: RelewiseRecommendationType;
	fillIn: boolean;
	includeVariants: boolean;
	displayedAtLocation: string;
	productFamilyId?: string;
	productMasterId?: string;
	categoryIds?: string[];
	subcategoryIds?: string[];
	collectionIds?: string[];
	predefinedProductFilters?: ProductFilter[];
	selectedIds?: string[];
}

export const defaultProductBundlePageSize = 36;

export const getRelewiseProductRecommendationsQuery = async (
	query: RelewiseProductRecommendationQuery,
): Promise<ProductListResponse> => {
	const recommendationsApi = new RecommendationApi({
		baseApiParams: { headers: setHeaders() },
	});

	const response = await recommendationsApi.recommendationRelewiseproductrecommendationsCreate({
		amount: query.amount,
		displayedAtLocation: query.displayedAtLocation,
		fillIn: query.fillIn,
		predefinedProductFilters: (query.predefinedProductFilters ?? []).flatMap((filter) => {
			return filter.attributeValues.map((value): FilterRequest => {
				return {
					filter: filter.attributeKey,
					value,
				};
			});
		}),
		categories: query.categoryIds ?? [],
		subCategories: query.subcategoryIds ?? [],
		collections: query.collectionIds ?? [],
		includeVariants: query.includeVariants,
		productFamilyId: query.productFamilyId,
		productMasterId: query.productMasterId,
		selectedIds: query.selectedIds ?? [],
		type: query.type,
	});

	return response.data;
};
