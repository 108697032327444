import React from 'react';
import { LayoutResponse, TranslationsWebsite } from 'generated/data-contracts';
import { MemoizedFooter as NewCMSFooter } from './new-cms';

interface FooterProps {
	critical?: boolean;
	layout?: LayoutResponse | null;
	translations?: TranslationsWebsite;
	className?: string;
}

const Footer: React.FunctionComponent<FooterProps> = ({ critical, layout, translations, className }) => {
	return <NewCMSFooter translations={translations} className={className} critical={critical} layout={layout} />;
};

export const MemoizedFooter = React.memo(Footer);
